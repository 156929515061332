<template>
  <div class="pageBok">
    <div>
      <el-button type="primary" @click="dialogFormVisible = true"
                 v-if="inspectButton('cos:mak:add')"
        >添加开票信息</el-button
      >
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="title" label="开票抬头" width="180">
      </el-table-column>
      <el-table-column prop="itin" label="纳税人识别号" width="180">
      </el-table-column>
      <el-table-column prop="address" label="发票地址"> </el-table-column>
      <el-table-column prop="phone" label="电话"> </el-table-column>
      <el-table-column prop="bank" label="开票银行"> </el-table-column>
      <el-table-column prop="email" label="邮箱"> </el-table-column>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <el-button
            @click="handleClick(scope.row)"
            :disabled="scope.row.is_default ? true : false"
            type="text"
            size="small"
            >设为默认</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="添加开票信息"
      width="500px"
      :visible.sync="dialogFormVisible"
      @close="close"
    >
      <el-form :model="form" ref="ruleForm" :rules="rules">
        <el-form-item
          label="开票抬头"
          label-width="120px"
          required
          :show-message="false"
          prop="title"
        >
          <el-input v-model="form.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="纳税人识别号"
          label-width="120px"
          required
          :show-message="false"
          prop="itin"
        >
          <el-input v-model="form.itin" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="发票地址"
          label-width="120px"
          required
          :show-message="false"
          prop="address"
        >
          <el-input v-model="form.address" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="电话"
          label-width="120px"
          required
          :show-message="false"
          prop="phone"
        >
          <el-input v-model="form.phone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="开票银行"
          label-width="120px"
          required
          :show-message="false"
          prop="bank"
        >
          <el-input v-model="form.bank" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="邮箱"
          label-width="120px"
          required
          :show-message="false"
          prop="email"
        >
          <el-input v-model="form.email" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        title: "",
        itin: "",
        address: "",
        phone: "",
        bank: "",
        email: "",
      },
      rules: {},
      dialogFormVisible: false,
      tableData: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    close() {
      this.form = {
        title: "",
        itin: "",
        address: "",
        phone: "",
        bank: "",
        email: "",
      };
    },
    async handleClick(row) {
      let data = await this.$store.dispatch("baseConsole/addinvoiceMsg", {
        id: row.id,
        is_default: 1,
        company_id: this.USER_INFO.company_id,
      });
      if (data.success) {
        this.$message({
          message: "设置成功",
          type: "success",
        });
        this.getList();
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let params = this.form;
          params.company_id = this.USER_INFO.company_id;
          let data = await this.$store.dispatch(
            "baseConsole/addinvoiceMsg",
            params
          );
          if (data.success) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.dialogFormVisible = false;
            this.getList();
          }
          console.log(data);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async getList() {
      let data = await this.$store.dispatch("baseConsole/getinvoiceList", {
        company_id: this.USER_INFO.company_id,
        nopage:1
      });
      this.tableData = data.data;
    },
  },
};
</script>

<style lang="less" scoped>
.pageBok {
  padding: 30px;
}
</style>
